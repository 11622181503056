import styled from "@emotion/styled";
import PropTypes from "prop-types";
import Button from "./elements/Button";
import { button } from "../styles/typography";
import { useStaticQuery, graphql } from "gatsby";
import color from "../styles/colors";
import Image from "gatsby-image";
import listBulletIcon from "../images/vectors/listBullet.svg"
import starsGif from "../images/illustrations/Stars_Desktop.webp";
import starsGifMobile from "../images/illustrations/Stars_Mobile.webp";
import useBreakpoint from 'use-breakpoint';
import {
	container,
	gridc,
	grid,
	screen,
	spacingRestricted,
} from "../styles/mixins";


const BREAKPOINTS = { mobile: 0, tablet: 768 };

const ContainerEl = styled.div`
	${container()}
`;

const Wrapper = styled.div`
	border-radius: 15px;
	background-color: ${props => props.background ? color(props.background) : color("beige")};
	padding: 1.6em;
	${gridc(3, 44)}

	@media ${screen("md")} {
		border-radius: 15px 15px 150px 15px;
		padding: 2em;
		${grid(44)}
	}

	@media ${screen("lg")} {
		padding: 3.6em;
	}
`;

const LeftColumn = styled.div`
	${gridc(1, 20)}
	max-width: 500px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const RightColumn = styled.div`
	${gridc(25, 44)}
	padding-bottom: 180px;

	@media ${screen("lg")} {
		padding-bottom: 40%;
	}
`;

const Heading = styled.h2`
	font-size: 40px;
	line-height: 1.1;
  letter-spacing: -0.01em;
	font-weight: 500;
  max-width: 350px;
	${gridc(2, 38)}
  ${spacingRestricted("margin-bottom", "m")};

	em {
		color: ${color("orange")};
		font-style: normal;
	}

	@media ${screen("md")} {
		font-size: 38px;
		max-width: 550px;
		${spacingRestricted("margin-bottom", "xxl")};
	}

	@media ${screen("md")} {
		font-size: 45px;
	}
`;

const RichText = styled.div`
	font-size: 20px;
	line-height: 1.1;
  letter-spacing: -0.01em;
	font-weight: 400;
	${gridc(2, 38)}

	li {
		position: relative;
		padding-left: 38px;
		${spacingRestricted("margin-bottom", "s")};

		&:before {
			content: '';
			width: 24px;
			height: 24px;
			background-image: url(${listBulletIcon});
			position: absolute;
			left: 0;
			top: 2px;

			@media ${screen("md")} {
				top: 7px;
			}
		}
	}

	@media ${screen("md")} {
		font-size: 28px;
	}

	@media ${screen("lg")} {
		font-size: 32px;
	}
`;

const Cta = styled.div`
	${gridc(3, 40)}
	${grid(40)}
	margin-top: 75px;

	@media ${screen("md")} {
		${gridc(4, 15)}
		position: static;
		margin-top: 30px;
	}
`;

const CtaDesktop = styled(Cta)`
	display: none;

	@media ${screen("md")} {
		display: block;
	}
`;

const CtaMobile = styled(Cta)`

	@media ${screen("md")} {
		display: none;
	}
`;

const CtaButton = styled(Button)`
	width: 100%;

	${button};
	${gridc(1, 42)}

	@media ${screen("md")} {
		transform: none;
		width: auto;
		max-width: none;
		margin-right: 0.5em;
		margin-bottom: 0.5em;
	}
`;

const ImageWrapper = styled.div`
	position: absolute;
	bottom: 0;
	right: 50%;
	transform: translate(50%, 18%);
	width: 100%;
	max-width: 270px;

	@media ${screen("md")} {
		max-width: 330px;
		right: 0;
		transform: translate(-34%, -20%);
	}
`;

const Section = styled.section`
	padding-bottom: 80px;
	background-color: ${color("purple")};

	${Heading} {
		color: ${props => props.colorTheme === "dark" ? color("beige") : color("green")};
	}

	${RichText} {
		color: ${props => props.colorTheme === "dark" ? color("white") : color("green")};
	}

	@media ${screen("md")} {
		padding-bottom: 100px;
	}
`;

const ListTextCtaSection = ({
	data,
	background,
	colorTheme
}) => {

  const { breakpoint, maxWidth, minWidth } = useBreakpoint(
    BREAKPOINTS,
    'tablet',
  )

	return data && data.heading && data.text ? (
		<Section colorTheme={colorTheme}>
			<ContainerEl>
				<Wrapper background={background}>
					<LeftColumn>
						<Heading dangerouslySetInnerHTML={{ __html: data.heading }}></Heading>
						<CtaDesktop>
							{data?.button && (
								<CtaButton
									url={data.button.url}
									target={data.button.target}
									linkType={data.button.type}
									element={data.button?.element}
									external={data.button.type !== "entry"}
									data-event-name="select_cta"
									data-content-type="Go to page"
									data-content-destination={data.button.url}
									data-content-text={data.button.text}>
									{data.button.text}
								</CtaButton>
							)}
						</CtaDesktop>
					</LeftColumn>
					<RightColumn>
						<RichText dangerouslySetInnerHTML={{ __html: data.text }}></RichText>
						<CtaMobile>
							{data?.button && (
								<CtaButton
									url={data.button.url}
									target={data.button.target}
									linkType={data.button.type}
									element={data.button?.element}
									external={data.button.type !== "entry"}
									data-event-name="select_cta"
									data-content-type="Go to page"
									data-content-destination={data.button.url}
									data-content-text={data.button.text}>
									{data.button.text}
								</CtaButton>
							)}
						</CtaMobile>
						<ImageWrapper>
							{breakpoint == "tablet" ? (
								<img src={starsGif} alt="" />
							) : (
								<img src={starsGifMobile} alt="" />
							)}
						</ImageWrapper>
					</RightColumn>
				</Wrapper>
			</ContainerEl>
		</Section>
	) : null;
};

ListTextCtaSection.propTypes = {
	colorTheme: PropTypes.string,
};

ListTextCtaSection.defaultProps = {
	colorTheme: null,
};

export default ListTextCtaSection;
