import React, { useState, useRef, useEffect, useCallback } from "react";
import Slider from "./Slider";
import { h3, h6, pSmall } from "../styles/typography";
import styled from "@emotion/styled";

const TopTextBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
`;

const H3 = styled.h3`
	${h3}
`;

const H6 = styled.h3`
	${h6}
`;

const P = styled.p`
	${pSmall}
`;

const BottomTextBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

export default function HowLongSlider({ updateData }) {
	const min = 1;
	const max = 5;
	const defaultVal = 5;
	const [inputVal, setInputVal] = useState(defaultVal);

	useEffect(() => {
		updateData(inputVal);
	}, [inputVal]);

	return (
		<>
			<TopTextBox>
				<H6>FOR HOW LONG</H6>
				<H3>{inputVal} years</H3>
			</TopTextBox>
			<Slider
				onUpdate={setInputVal}
				range={{ min, max }}
				setSlide={defaultVal}
			/>
			<BottomTextBox>
				<P>{min} year</P>
				<P>{max} years</P>
			</BottomTextBox>
		</>
	);
}
