import styled from "@emotion/styled";
import { useStaticQuery, graphql } from "gatsby";
import Section, { Para } from "./elements/Section";
import PropTypes from "prop-types";
import {
	container,
	gridc,
	screen,
	spacingRestricted,
	fluidRestricted
} from "../styles/mixins";
import Button from "./elements/Button";
import { button } from "../styles/typography";
import color, { themeColors } from "../styles/colors";
import Image from "gatsby-image";
import clockGif from "../images/illustrations/Time_Desktop.webp";
import clockGifMobile from "../images/illustrations/Time_Mobile.webp";
import useBreakpoint from 'use-breakpoint';

const eggplant = color("purple");
const BREAKPOINTS = { mobile: 0, tablet: 768 };

const SectionEl = styled(Section)`
	padding-bottom: ${props => props.noPadding ? "0px !important" : "80px !important" };
	padding-top: 0 !important;

	@media ${screen("md")} {
		padding-bottom: ${props => props.noPadding ? "0px !important" : "120px !important" };
		padding-top: 0 !important;
	}
`;

const ContainerEl = styled.div`
	${container()}
`;

const ContainerHeight = styled.div`
	width: 100%;
	position: relative;
	background-color: ${props => color(props.bgColor)};
	border-radius: 20px;
	overflow: hidden;

	@media ${screen("md")} {
		display: flex;
		flex-direction: row-reverse;
	}
`;

const ContainerWidth = styled.div`
	${gridc(3, 44)}

	@media ${screen("md")} {
		${gridc(3, 44)}
	}
`;

const Content = styled.div`
	position: relative;
	width: 100%;
	color: ${eggplant};
	padding: 30px;

	@media ${screen("md")} {
		max-width: 60%;
		padding: 2.6em;
	}

	@media ${screen("lg")} {
		padding: 3.6em;
	}
`;

const Text = styled.div`
	color: ${props => themeColors[props.colorTheme].bg};

	@media ${screen("md")} {
		display: block;
		margin-top: 0;
	}
`;

const Heading = styled.h2`
	font-size: 40px;
	line-height: 1.1;
  letter-spacing: -0.01em;
	font-weight: 500;
  max-width: 300px;
  ${spacingRestricted("margin-bottom", "s")};

	em {
		color: ${color("orange")};
		font-style: normal;
	}

	@media ${screen("md")} {
		font-size: 55px;
		max-width: 550px;
		${spacingRestricted("margin-bottom", "m")};
	}

	@media ${screen("lg")} {
		font-size: 68px;
		max-width: 600px;
	}
`;

const P = styled(Para)`
	font-size: 20px;
	line-height: 26px;
	max-width: 400px;

	@media ${screen("lg")} {
		font-size: 22px;
		line-height: 28px;
	}
`;

const Cta = styled.div`
	margin-top: 3em;

	@media ${screen("md")} {
		margin-top: 5em;
	}

	@media ${screen("lg")} {
		margin-top: 8em;
	}
`;

const CtaButton = styled(Button)`
	width: 100%;

	${button};
	${gridc(1, 42)}

	@media ${screen("md")} {
		transform: none;
		width: auto;
		max-width: none;
		margin-right: 0.5em;
		margin-bottom: 0.5em;
	}
`;

const ImageWrapper = styled.div`
	width: 100%;
	max-width: 270px;
	margin-top: 30px;
	margin-left: auto;
	transform: translateX(20%);

	@media ${screen("md")} {
		margin-top: auto;
		margin-bottom: 60px;
		max-width: 300px;
	}

	@media ${screen("lg")} {
		max-width: 400px;
	}
`;

const CtaSection = ({
	colorTheme = "dark",
	data,
	noPadding = false
}) => {

	const { breakpoint, maxWidth, minWidth } = useBreakpoint(
    BREAKPOINTS,
    'tablet',
  )

	return data ? (
		<SectionEl colorTheme={colorTheme} noPadding={noPadding}>
			<ContainerEl>
				<ContainerWidth>
					<ContainerHeight bgColor={data.theme}>
							<ImageWrapper>
								{breakpoint == "tablet" ? (
									<img src={clockGif} alt="" />
								) : (
									<img src={clockGifMobile} alt="" />
								)}

							</ImageWrapper>
						<Content>
							<Text colorTheme={colorTheme}>
								{data.heading && <Heading><div dangerouslySetInnerHTML={{ __html: data.heading }} /></Heading>}
								{data.text && <P as="div" dangerouslySetInnerHTML={{ __html: data.text }} />}
							</Text>
							<Cta>
								{data?.button.url && (
									<CtaButton
										url={data.button.url}
										target={data.button.target}
										linkType={data.button.type}
										element={data.button?.element}
										external={data.button.type !== "entry"}
										data-event-name="select_cta"
										data-content-type="Go to page"
										data-content-destination={data.button.url}
										data-content-text={data.button.text}>
										{data.button.text}
									</CtaButton>
								)}
							</Cta>
						</Content>
					</ContainerHeight>
				</ContainerWidth>
			</ContainerEl>
		</SectionEl>
	) : null;
};

CtaSection.propTypes = {
	colorTheme: PropTypes.string,
};

CtaSection.defaultProps = {
	colorTheme: "dark",
};

export default CtaSection;
