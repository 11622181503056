import React, { useState, useEffect } from "react";
import { h3, h6, pSmall } from "../styles/typography";
import InputSlider from "./InputSlider";
import styled from "@emotion/styled";
import { spacingRestricted, screen } from "../styles/mixins";

const TopTextBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	${spacingRestricted("margin-bottom", "xxs")}
`;

const BottomTextBox = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const H3 = styled.h3`
	${h3}
`;

const Label = styled.label`
	${h6}
`;

const P = styled.p`
	${pSmall}
`;

export default function LoanAmountSlider({ updateData }) {
	const [inputVal, setInputVal] = useState(1);

	useEffect(() => {
		updateData(inputVal);
	}, [inputVal]);

	return (
		<>
			<TopTextBox>
				<Label htmlFor="loan-amount">LOAN AMOUNT</Label>
			</TopTextBox>

			<InputSlider
				onUpdate={setInputVal}
				range={{ min: 3000, max: 30000 }}
				defaultVal={10000}
				trackingName="Loan Amount Slider"
				id="loan-amount"
			/>

			<BottomTextBox>
				<P>$3000</P>
				<P>$30,000</P>
			</BottomTextBox>
		</>
	);
}
