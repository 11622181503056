import { useEffect, useState } from "react";
import { breakpoints } from "../styles/mixins";

export default function useMediaQuery(query, initialValue) {
	const [matches, setMatches] = useState(initialValue);

	useEffect(() => {
		const mql = window.matchMedia(query);
		const listener = (e) => {
			setMatches(e.matches);
		};

		mql.addListener(listener);
		listener(mql);

		return () => mql.removeListener(listener);
	}, [query]);

	return matches;
}

export function useScreenQuery(screen) {
	return useMediaQuery(`(min-width: ${breakpoints[screen]}px)`, true);
}
