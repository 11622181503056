import React, { useEffect, useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { Heading2, RichText } from "./elements/Section";
import PropTypes from "prop-types";
import color from "../styles/colors";
import Image from "gatsby-image";
import listBulletPurpleIcon from "../images/vectors/listBulletPurple.svg";
import {
	padding,
	spacingRestricted,
	screen,
	container,
	gridColumnByMargin,
	fluidRestricted,
	grid,
  gridc,
	fluidPercentageRestricted,
} from "../styles/mixins";
import { metaSmall } from "../styles/typography";
import Button from "./elements/Button";
import FluidImagerX from "./elements/FluidImagerX";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { ReactComponent as Cross } from "../images/vectors/cross.svg";

const Heading = styled.h3`
  font-size: 40px;
	line-height: 1.2;
  font-weight: 500;
  letter-spacing: -0.01em;
  margin-bottom: 25px;

  em {
    color: ${color("orange")};
    font-style: normal;
  }

	@media ${screen("md")} {
    font-size: 45px;
    margin-bottom: 45px;
	}
`;

const ModalEl = styled.div`
	position: fixed;
	z-index: 150;
	width: 100%;
	height: 100%;
	top: 0;
	left: ${(props) => (props.shown ? "0" : "-100%")};
	opacity: ${(props) => (props.fade ? 1 : 0)};
	visibility: ${(props) => (props.shown ? "visible" : "hidden")};
	transition: opacity 0.2s;
	text-align: left;
`;

const ModalScrollWrapper = styled.div`
	overflow-y: auto;
	position: absolute;
	width: 100vw;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
`;

const Overlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: ${color("purple")};
	opacity: 0.8;
`;

const ModalContainer = styled.div`
	${container()};
`;

const SpacingEl = styled.div`
	padding: 50px 0;
	${spacingRestricted("padding-top", "xl")};
	${spacingRestricted("padding-bottom", "m")};
  ${gridColumnByMargin(2, 48)};
	pointer-events: all;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;

	@media ${screen("md")} {
		${spacingRestricted("padding-top", "xxl")};
		${spacingRestricted("padding-bottom", "xxl")};
	}

	@media ${screen("lg")} {
		${gridColumnByMargin(3, 48)};
	}
`;

const Content = styled.div`
	color: ${color("purple")};
	position: relative;

	@media ${screen("md")} {
		border-radius: 0;
	}
`;

const MainText = styled(RichText)`
  font-size: 17px;
  line-height: 1.2;
  font-weight: 400;

  @media ${screen("md")} {
    font-size: 22px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      margin-bottom: 10px;
      padding-left: 36px;
      position: relative;

      &:before {
        content: '';
        width: 24px;
        height: 24px;
        background-image: url(${listBulletPurpleIcon});
        position: absolute;
        left: 0;
        top: 1px;
      }

      @media ${screen("md")} {
        margin-bottom: 14px;
      }
    }
  }
`;

const CloseButton = styled.button`
	position: absolute;
	${fluidRestricted("top", 8, 14, "designM", "designD")};
	${fluidRestricted("right", 8, 14, "designM", "designD")};
	${fluidRestricted("padding", 8, 14, "designM", "designD")};
	z-index: 50;

	svg {
		${fluidRestricted("width", 15, 30, "designM", "designD")};
		transition: opacity 0.2s;
		stroke: ${color("orange")};
	}

	&:hover {
		svg {
			opacity: 0.8;
		}
	}

	&:focus {
		outline: none;
	}
`;

const ModalContent = styled.div`
  background-color: ${(props) => (props.background ? color(props.background) : color("pink"))}; ;
  border-radius: 15px;
  padding: 25px;
  display: flex;
  flex-direction: column-reverse;
  max-width: 360px;

  @media ${screen("md")} {
    ${grid(44)};
    padding: 40px;
    max-width: unset;
    border-radius: 15px 15px 150px 15px;
  }

  @media ${screen("lg")} {
    padding: 70px;
  }
`;

const LeftColumn = styled.div`
  ${gridc(1, 24)}

  @media ${screen("md")} {
    ${gridc(1, 26)}
  }

  @media ${screen("lg")} {
    ${gridc(1, 24)}
  }
`;

const RightColumn = styled.div`
  ${gridc(30, 10)}

  @media ${screen("md")} {
    ${gridc(29, 14)}
		display: flex;
		align-items: flex-end;
  }

  @media ${screen("lg")} {
    ${gridc(33, 10)}
  }
`;

const ImageWrapper = styled.div`
	width: 100%;
  max-width: 100px;
  margin-bottom: 75px;
  margin-top: -70px;

  @media ${screen("md")} {
    max-width: 220px;
    margin: 0 auto;
  }
`;

const FancyModal = ({
	className,
	open,
	closeModal,
  modalTitle,
  modalList,
	modalBackground,
	modalIllustration
}) => {
	const modalWrapper = useRef();
	const [fade, setFade] = useState(false);
	const [show, setShow] = useState(false);

	const stopPropagation = (e) => {
		e.stopPropagation();
	};

	useEffect(() => {
		if (open) {
			disableBodyScroll(modalWrapper.current, { reserveScrollBarGap: true });
			setShow(true);
			setFade(true);

			window.dataLayer = window.dataLayer || [];
			dataLayer.push({
				event: "show_overlay",
				event_info: {
					event_category: "Overlays",
					event_action: "Overlay displayed - Information Modal",
					event_label: modalTitle,
				},
			});
		} else {
			enableBodyScroll(modalWrapper.current);

			setFade(false);

			setTimeout(() => setShow(false), 500);
		}
	}, [open]);

	useEffect(() => {
		return () => {
			enableBodyScroll(modalWrapper.current);
		};
	}, []);

  const query = useStaticQuery(graphql`
    query fancyModal {
      door: file(relativePath: { eq: "illustrations/opening-door.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            sizes
          }
        }
      }
			vacation: file(relativePath: { eq: "illustrations/Beach_Mobile.webp" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            sizes
          }
        }
      }
			stars: file(relativePath: { eq: "illustrations/Stars_Mobile.webp" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            sizes
          }
        }
      }
			driving: file(relativePath: { eq: "illustrations/Car_Mobile.webp" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            sizes
          }
        }
      }
    }
  `);

	const illustrations = {
		door: query?.door,
		vacation: query?.vacation,
		stars: query?.stars,
		driving: query?.driving
	}

	return (
		<ModalEl className={className} shown={show} fade={fade}>
			<Overlay onClick={closeModal} />
			<ModalScrollWrapper ref={modalWrapper} onClick={closeModal}>
				<ModalContainer>
					<SpacingEl>
						<Content onClick={stopPropagation}>
							<CloseButton onClick={closeModal}>
								<Cross />
							</CloseButton>
							<ModalContent background={modalBackground}>
								<LeftColumn>
									<Heading dangerouslySetInnerHTML={{ __html: modalTitle }} />
									<MainText text={modalList} />
								</LeftColumn>
                <RightColumn>
                  <ImageWrapper>
                    <Image fluid={illustrations[modalIllustration]?.childImageSharp.fluid} />
                  </ImageWrapper>
                </RightColumn>
							</ModalContent>
						</Content>
					</SpacingEl>
				</ModalContainer>
			</ModalScrollWrapper>
		</ModalEl>
	);
};

FancyModal.propTypes = {
	open: PropTypes.bool,
	closeModal: PropTypes.func,
};

FancyModal.defaultProps = {
	open: false,
};

export default FancyModal;
