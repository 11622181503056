import React, { useEffect, useState, useRef } from "react";
import styled from "@emotion/styled";
import { RichText } from "./elements/Section";
import PropTypes from "prop-types";
import color from "../styles/colors";
import listBulletPurpleIcon from "../images/vectors/listBulletPurple.svg";
import {
	spacingRestricted,
	screen,
	container,
	gridColumnByMargin,
	fluidRestricted,
	grid,
	gridc,
} from "../styles/mixins";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { ReactComponent as Cross } from "../images/vectors/cross.svg";

const Heading = styled.h3`
	font-size: 40px;
	line-height: 1.2;
	font-weight: 500;
	letter-spacing: -0.01em;
	margin-bottom: 45px;

	em {
		color: ${color("orange")};
		font-style: normal;
	}

	@media ${screen("md")} {
		font-size: 45px;
	}
`;

const ModalEl = styled.div`
	position: fixed;
	z-index: 150;
	width: 100%;
	height: 100%;
	top: 0;
	left: ${(props) => (props.shown ? "0" : "-100%")};
	opacity: ${(props) => (props.fade ? 1 : 0)};
	visibility: ${(props) => (props.shown ? "visible" : "hidden")};
	transition: opacity 0.2s;
	text-align: left;
`;

const ModalScrollWrapper = styled.div`
	overflow-y: auto;
	position: absolute;
	width: 100vw;
	height: 100%;
	top: 0;
	left: 0;
	display: flex;
	flex-direction: column;
`;

const Overlay = styled.div`
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: ${color("purple")};
	opacity: 0.8;
`;

const ModalContainer = styled.div`
	${container()};
`;

const SpacingEl = styled.div`
	padding: 50px 0;
	${spacingRestricted("padding-top", "xl")};
	${spacingRestricted("padding-bottom", "m")};
	${gridColumnByMargin(2, 48)};
	pointer-events: all;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;

	@media ${screen("md")} {
		${spacingRestricted("padding-top", "xxl")};
		${spacingRestricted("padding-bottom", "xxl")};
	}

	@media ${screen("lg")} {
		${gridColumnByMargin(3, 48)};
	}
`;

const Content = styled.div`
	color: ${color("purple")};
	position: relative;

	@media ${screen("md")} {
		border-radius: 0;
	}
`;

const MainText = styled(RichText)`
	font-size: 17px;
	line-height: 1.2;
	font-weight: 400;

	@media ${screen("md")} {
		font-size: 22px;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;

		li {
			margin-bottom: 10px;
			padding-left: 36px;
			position: relative;

			&:before {
				content: "";
				width: 24px;
				height: 24px;
				background-image: url(${listBulletPurpleIcon});
				position: absolute;
				left: 0;
				top: 1px;
			}

			@media ${screen("md")} {
				margin-bottom: 14px;
			}
		}
	}
`;

const CloseButton = styled.button`
	position: absolute;
	${fluidRestricted("top", 8, 14, "designM", "designD")};
	${fluidRestricted("right", 8, 14, "designM", "designD")};
	${fluidRestricted("padding", 8, 14, "designM", "designD")};
	z-index: 50;

	svg {
		${fluidRestricted("width", 15, 30, "designM", "designD")};
		transition: opacity 0.2s;
		stroke: ${color("orange")};
	}

	&:hover {
		svg {
			opacity: 0.8;
		}
	}

	&:focus {
		outline: none;
	}
`;

const ModalContent = styled.div`
	background-color: ${(props) =>
		props.background ? color(props.background) : color("pink")};
	border-radius: 15px;
	padding: 120px 25px 40px 25px;
	display: flex;
	flex-direction: column;
	max-width: 360px;

	@media ${screen("md")} {
		padding: 50px 120px 50px 50px;
		max-width: unset;
		border-radius: 15px 15px 150px 15px;
	}

	@media ${screen("lg")} {
		padding: 70px 150px 70px 70px;
	}
`;

const SimpleModal = ({
	className,
	open,
	closeModal,
	modalTitle,
	modalList,
}) => {
	const modalWrapper = useRef();
	const [fade, setFade] = useState(false);
	const [show, setShow] = useState(false);

	const stopPropagation = (e) => {
		e.stopPropagation();
	};

	useEffect(() => {
		if (open) {
			disableBodyScroll(modalWrapper.current, { reserveScrollBarGap: true });
			setShow(true);
			setFade(true);

			window.dataLayer = window.dataLayer || [];
			dataLayer.push({
				event: "show_overlay",
				event_info: {
					event_category: "Overlays",
					event_action: "Overlay displayed - Information Modal",
					event_label: modalTitle,
				},
			});
		} else {
			enableBodyScroll(modalWrapper.current);

			setFade(false);

			setTimeout(() => setShow(false), 500);
		}
	}, [open]);

	useEffect(() => {
		return () => {
			enableBodyScroll(modalWrapper.current);
		};
	}, []);

	return (
		<ModalEl className={className} shown={show} fade={fade}>
			<Overlay onClick={closeModal} />
			<ModalScrollWrapper ref={modalWrapper} onClick={closeModal}>
				<ModalContainer>
					<SpacingEl>
						<Content onClick={stopPropagation}>
							<CloseButton onClick={closeModal}>
								<Cross />
							</CloseButton>
							<ModalContent>
								<Heading dangerouslySetInnerHTML={{ __html: modalTitle }} />
								<MainText text={modalList} />
							</ModalContent>
						</Content>
					</SpacingEl>
				</ModalContainer>
			</ModalScrollWrapper>
		</ModalEl>
	);
};

SimpleModal.propTypes = {
	open: PropTypes.bool,
	closeModal: PropTypes.func,
};

SimpleModal.defaultProps = {
	open: false,
};

export default SimpleModal;
